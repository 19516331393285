import Hygraph from './hygraph/hygraph';
import Filedb from './filedb/filedb';

export class ArticleModel {
    ref?: string;
    path?: string;
    locale?: string;
    title?: string;
    description?: string;
    content?: string;
    image?: string;
    publishedAt?: string;
    updatedAt?: string;
    category: CategoryModel = {};
    author: AuthorModel = {};
    seo: {
        twitter?: {
            siteAccount?: string;
        };
        image?: string;
    } = {};
}

export class SettingModel {
    siteName?: string;
    blogName?: string;
    blogDescription?: string;
    defaultLocale?: string;
    blogSectionsBefore: SectionModel[] = [];
    blogSectionsAfter: SectionModel[] = [];
    blogEnable: boolean = false;
    docEnable: boolean = false;
}

export class CategoryModel {
    name?: string;
    path?: string;
    locale?: string;
    description?: string;
}

export class AuthorModel {
    firstname?: string;
    lastname?: string;
    twitterAccount?: string;
    avatar?: string;
    role?: string;
    path?: string;
    locale?: string;
    bio?: string;
}

export class PageModel {
    ref?: string;
    path?: string;
    locale?: string;
    publishedAt?: string;
    updatedAt?: string;
    seo: {
        twitter?: {
            siteAccount?: string;
            creatorAccount?: string;
        };
        image?: string;
        title?: string;
        author?: string;
        description?: string;
    } = {};
    sections: SectionModel[] = [];
}

export class SectionModel {
    type?: string;
    properties = {};
}

export enum Status {
    DRAFT = 'DRAFT',
    PUBLISHED = 'PUBLISHED',
}

export interface DatabaseImplementation {
    getPageBySlug(slug: string, status: Status): Promise<PageModel>;
    getPages(status: Status): Promise<PageModel[]>;
    getArticles(status: Status): Promise<ArticleModel[]>;
    getArticleBySlug(slug: string, status: Status): Promise<ArticleModel>;
    getAuthors(status: Status): Promise<AuthorModel[]>;
    getAuthorBySlug(slug: string, status: Status): Promise<AuthorModel>;
    getCategories(status: Status): Promise<CategoryModel[]>;
    getCategoryBySlug(slug: string, status: Status): Promise<CategoryModel>;
    getSetting(status: Status): Promise<SettingModel>;
}

export class DatabaseService {
    private readonly appEnvStatus: Status = Status[process.env.REACT_APP_DB_STAGE as keyof typeof Status];

    private readonly db: DatabaseImplementation;

    constructor(fetch: any) {
        console.log('env.Status - ', this.appEnvStatus);
        this.db = process.env.REACT_APP_TARGET !== 'HYGRAPH' ? new Filedb(fetch) : new Hygraph(fetch);
    }

    async getPageBySlug(slug: string): Promise<PageModel> {
        return this.db.getPageBySlug(slug, this.appEnvStatus);
    }

    async getPages(): Promise<PageModel[]> {
        return this.db.getPages(this.appEnvStatus);
    }

    async getArticles(): Promise<ArticleModel[]> {
        return this.db.getArticles(this.appEnvStatus);
    }

    async getArticleBySlug(slug: string): Promise<ArticleModel> {
        return this.db.getArticleBySlug(slug, this.appEnvStatus);
    }

    async getAuthors(): Promise<AuthorModel[]> {
        return this.db.getAuthors(this.appEnvStatus);
    }

    async getAuthorBySlug(slug: string): Promise<AuthorModel> {
        return this.db.getAuthorBySlug(slug, this.appEnvStatus);
    }

    async getCategories(): Promise<CategoryModel[]> {
        return this.db.getCategories(this.appEnvStatus);
    }

    async getCategoryBySlug(slug: string): Promise<CategoryModel> {
        return this.db.getCategoryBySlug(slug, this.appEnvStatus);
    }

    async getSetting(): Promise<SettingModel> {
        return this.db.getSetting(this.appEnvStatus);
    }
}
