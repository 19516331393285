import { CommandLineIcon, InboxStackIcon, StarIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';

/*
1. Unlimited notification content possibilities
2. Notification Center customization
3. Live preview notification editor
4. Inform in real-time, when event happens (web and mobile screenshot)
5. Give your users power on their notification management
*/

const useCases = [
    {
        name: 'Onboarding',
        ref: 'onboarding',
        description: 'Native support of read / unread notifications and counter',
        icon: InboxStackIcon,
    },
    {
        name: 'Administrative',
        ref: 'administrative',
        description: 'Inform users when event happens, not minutes or hours later',
        icon: StarIcon,
    },
    {
        name: 'Background tasks',
        ref: 'backgroundTasks',
        description: 'Integrate Notifizz in your web app in minutes',
        icon: CommandLineIcon,
    },
    {
        name: 'Guidance',
        description: 'Integrate Notifizz in your web app in minutes',
        icon: CommandLineIcon,
    },
    {
        name: 'Alerting',
        description: 'Integrate Notifizz in your web app in minutes',
        icon: CommandLineIcon,
    },
    {
        name: 'Commercial offers',
        description: 'Integrate Notifizz in your web app in minutes',
        icon: CommandLineIcon,
    },
    {
        name: 'Informations',
        description: 'Integrate Notifizz in your web app in minutes',
        icon: CommandLineIcon,
    },
    {
        name: 'Persistent feedback',
        description: 'Integrate Notifizz in your web app in minutes',
        icon: CommandLineIcon,
    },
];

export default function CustomerUseCases() {
    const [selectedUseCase, setSelectedUseCase] = useState('onboarding');

    return (
        <div className="overflow-hidden bg-white py-24">
            <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
                <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
                    <div className="px-6 md:px-0 lg:pr-4 lg:pt-4">
                        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
                            <h2 className="mt-14 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                Endless notifications possibilities{' '}
                                <span className="bg-gradient-to-r from-[#DB1783] to-orange-500 bg-clip-text text-transparent">
                                    for all users needs
                                </span>
                            </h2>
                            <div className="mt-6 grid grid-cols-2">
                                {useCases.map((item, i) => (
                                    <div
                                        key={i}
                                        className={`mt-6 flow-root cursor-pointer rounded-md ${
                                            item.ref === selectedUseCase ? ' bg-orange-100' : ' hover:bg-gray-100'
                                        }`}
                                    >
                                        <button
                                            key={item.name}
                                            onClick={() => setSelectedUseCase(item.ref as string)}
                                            className="flex gap-x-4 p-2 text-sm font-semibold leading-6 text-gray-900"
                                        >
                                            <item.icon
                                                className={`h-6 w-6 flex-none ${
                                                    item.ref === selectedUseCase ? ' text-orange-500' : ' text-gray-400'
                                                }`}
                                                aria-hidden="true"
                                            />
                                            {item.name}
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="sm:px-6 lg:px-0">
                        <div className="mt-20 w-96 flex-none">
                            <div className="relative rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
                                {selectedUseCase === 'onboarding' && (
                                    <div>
                                        <img src="/notifications-images/notif-2.png" alt="notification 1" />
                                        <img src="/notifications-images/notif-2.png" alt="notification 2" />
                                        <img src="/notifications-images/notif-2.png" alt="notification 3" />
                                    </div>
                                )}
                                {selectedUseCase === 'administrative' && (
                                    <div>
                                        <img src="/notifications-images/notif-1.png" alt="administrative notif 1" />
                                        <img src="/notifications-images/notif-1.png" alt="administrative notif 2" />
                                        <img src="/notifications-images/notif-1.png" alt="administrative notif 3" />
                                    </div>
                                )}
                                {selectedUseCase === 'backgroundTasks' && (
                                    <div>
                                        <img src="/notifications-images/notif-3.png" alt="task notif 1" />
                                        <img src="/notifications-images/notif-3.png" alt="task notif 1" />
                                        <img src="/notifications-images/notif-3.png" alt="task notif 1" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
