const registeredComponents: { [k: string]: any } = {};

export function registerComponent(name: string, component: any) {
    console.log('registerComponent', name);
    registeredComponents[name] = component;
    return registeredComponents[name];
}

export function getComponent(name?: string): any {
    console.log('getComponent', name, registeredComponents);
    if (!name) {
        return <></>;
    }
    return registeredComponents[name];
}
