import Highlighter from './components/highlighter';

export default function HeroColored({
    title,
    subtitle,
    catchphrase,
}: {
    title: string;
    subtitle: string;
    catchphrase: string;
}) {
    return (
        <div className="relative isolate">
            <div className="mx-auto max-w-6xl mt-10">
                <div className="flex flex-col gap-3 rounded-xl bg-gradient-to-r from-[#DB1783] to-orange-500 p-5 text-white">
                    <h2 className="text-2xl">
                        <Highlighter colored={false}>{title}</Highlighter>
                    </h2>
                    {subtitle && (
                        <span className="text-lg">
                            <Highlighter colored={false}>{subtitle}</Highlighter>
                        </span>
                    )}
                    <span className="mt-2 text-xl text-orange-200">
                        <Highlighter colored={false}>{catchphrase}</Highlighter>
                    </span>
                </div>
            </div>
        </div>
    );
}
