import React from 'react';
import { Button, Cta } from './components/cta';
import Highlighter from './components/highlighter';

const backgroundTop = (
    <div
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
    >
        <img src="background.svg" style={{ height: '1500px', width: '100%', opacity: '11%' }} alt="background" />
    </div>
);

export default function HeroV3({
    title,
    subtitle,
    buttons = [],
    imageUrl,
    imageAlt,
}: {
    title: string;
    subtitle: string;
    buttons: Button[];
    imageUrl: string;
    imageAlt: string;
}) {
    return (
        <div className="relative isolate overflow-hidden bg-white">
            {backgroundTop}
            <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:px-8 lg:py-40">
                <div className="mx-auto max-w-2xl">
                    <div className="mt-24 sm:mt-32 lg:mt-16"></div>
                    <h1 className="mt-34 tracking-normal text-center text-2xl font-bold leading-5 sm:leading-tight text-gray-900 sm:text-5xl">
                        <Highlighter colored={true}>{title}</Highlighter>
                    </h1>
                    <p className="mt-7 text-center text-xl text-gray-600">
                        <span className="leading-7">
                            <Highlighter colored={false}>{subtitle}</Highlighter>
                        </span>
                    </p>
                    <div className="mt-10 items-center gap-x-6 text-center">
                        {buttons.map((btn, i) => (
                            <div key={i}>
                                {Cta({
                                    ...btn,
                                    className:
                                        i === 0
                                            ? 'select-none cursor-pointer rounded-md bg-gradient-to-r from-[#DB1783] to-orange-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:brightness-110 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600'
                                            : 'select-none cursor-pointer bg-gradient-to-r from-[#DB1783] to-orange-500 bg-clip-text text-sm font-semibold leading-6 text-gray-900 text-transparent hover:brightness-150',
                                })}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="mx-auto mt-16 max-w-4xl sm:mt-24">
                    <img
                        src={imageUrl}
                        alt={imageAlt}
                        className="mb-[-14%] rounded-xl shadow-xl ring-1 ring-white/10 transition duration-300 ease-in"
                    />
                </div>
            </div>
        </div>
    );
}
