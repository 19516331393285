import { Article } from '../blogTheme/article';
import { ArticlesList } from '../blogTheme/articlesList';
import { Author } from '../blogTheme/author';
import { Category } from '../blogTheme/category';

const registeredBlogComponents: BlogComponents = {
    articleComponent: Article,
    articlesListComponent: ArticlesList,
    authorComponent: Author,
    categoryComponent: Category,
};

export interface BlogComponents {
    articleComponent: any;
    articlesListComponent: any;
    authorComponent: any;
    categoryComponent: any;
}

export function updateBlogComponents(articleComponent: any, articleListComponent: any): BlogComponents {
    console.log('registerBlogComponents');
    registeredBlogComponents.articleComponent = articleComponent;
    registeredBlogComponents.articlesListComponent = articleListComponent;
    return registeredBlogComponents;
}

export function getBlogComponents(): BlogComponents {
    console.log('getBlogComponents', registeredBlogComponents);
    return registeredBlogComponents;
}
