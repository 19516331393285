import { SettingModel } from './service/database';

export function buildAuthorUrlFromPath(authorLocale: string, authorPath: string, setting: SettingModel): string {
    authorPath = authorPath.replace(/^(\/)+/, '/'); // Remove additionnal / used to explicitly keep unique url path in Database (ex: / and /fr both are /. So one is / and other is //)
    return authorLocale !== setting.defaultLocale
        ? `/${authorLocale}/blog/author${authorPath}`
        : `/blog/author${authorPath}`;
}

export function buildCategoryUrlFromPath(categoryLocale: string, categoryPath: string, setting: SettingModel): string {
    categoryPath = categoryPath.replace(/^(\/)+/, '/'); // Remove additionnal / used to explicitly keep unique url path in Database (ex: / and /fr both are /. So one is / and other is //)
    return categoryLocale !== setting.defaultLocale
        ? `/${categoryLocale}/blog/category${categoryPath}`
        : `/blog/category${categoryPath}`;
}

export function buildArticleUrlFromPath(articleLocale: string, articlePath: string, setting: SettingModel): string {
    articlePath = articlePath.replace(/^(\/)+/, '/'); // Remove additionnal / used to explicitly keep unique url path in Database (ex: / and /fr both are /. So one is / and other is //)
    return articleLocale !== setting.defaultLocale ? `/${articleLocale}/blog${articlePath}` : `/blog${articlePath}`;
}

export function buildArticlesListUrl(locale: string, setting: SettingModel): string {
    return setting.defaultLocale !== locale ? `/${locale}/blog` : `/blog`;
}

export function buildPageUrl(locale: string, pagePath: string, setting: SettingModel): string {
    pagePath = pagePath.replace(/^(\/)+/, '/'); // Remove additionnal / used to explicitly keep unique url path in Database (ex: / and /fr both are /. So one is / and other is //)
    return setting.defaultLocale !== locale ? `/${locale}${pagePath}` : pagePath;
}
