import reactStringReplace from 'react-string-replace';

export default function Highlighter({ children, colored = false }: { children: any; colored: boolean }) {
    const childrenWithLineReturns = reactStringReplace(children, /(\\n)/g, (match, i) => <br key={'br' + i} />);

    return (
        <>
            {!colored &&
                reactStringReplace(childrenWithLineReturns, /\*\*([\w\s']+)\*\*/gm, (match, i) => (
                    <b key={'txt' + i}>{match}</b>
                ))}

            {colored &&
                reactStringReplace(childrenWithLineReturns, /\*\*([\w\s']+)\*\*/gm, (match, i) => (
                    <>
                        {' '}
                        <span className="highlight" key={'txt' + i}>
                            {match}
                        </span>
                    </>
                ))}
        </>
    );
}
