import {
    FacebookIcon,
    FacebookShareButton,
    RedditIcon,
    RedditShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    XIcon,
} from 'react-share';

export function ShareButtons({ url, title, twitterAccount }: { url: string; title?: string; twitterAccount?: string }) {
    return (
        <>
            <FacebookShareButton url={url}>
                <FacebookIcon size={32} round={true} className="hover:brightness-150" />
            </FacebookShareButton>

            <TwitterShareButton url={url} title={title} related={twitterAccount ? [twitterAccount] : []}>
                <XIcon size={32} round={true} className="hover:brightness-150" />
            </TwitterShareButton>

            <RedditShareButton url={url} title={title}>
                <RedditIcon size={32} round={true} className="hover:brightness-150" />
            </RedditShareButton>

            <WhatsappShareButton url={url} title={title}>
                <WhatsappIcon size={32} round={true} className="hover:brightness-125" />
            </WhatsappShareButton>

            <TelegramShareButton url={url} title={title}>
                <TelegramIcon size={32} round={true} className="hover:brightness-125" />
            </TelegramShareButton>
        </>
    );
}
