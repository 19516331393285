import { PageModel } from '../service/database';
import { Helmet } from 'react-helmet';
import { seoService } from '../service/seoService';
import React from 'react';
import { SectionsRenderer } from './sectionsRenderer';

export function PageRenderer({ allPages, page }: { allPages: PageModel[]; page: PageModel }) {
    const rootUrl = process.env.REACT_APP_ROOT_URL as string;
    const pageUrl = rootUrl + (page.path || '');
    const alternativesLocalesPageAvailable = allPages
        .filter((p) => p.ref === page.ref && p.path !== page.path)
        .map((p) => p.locale);
    return (
        <div>
            <Helmet
                htmlAttributes={{
                    lang: page.locale,
                }}
            >
                {seoService.basicMetaTags({
                    pageUrl: pageUrl,
                    description: page.seo.description,
                    title: page.seo.title,
                    image: page.seo.image ? `${rootUrl}${page.seo.image}` : seoService.defaultImage(rootUrl),
                })}
                {seoService.opengraphLocales(page.locale as string, alternativesLocalesPageAvailable as string[])}
                {seoService.opengraphWebsiteMetaTags()}
                {seoService.twitterMetaTags(
                    {
                        pageUrl: pageUrl,
                        description: page.seo.description,
                        title: page.seo.title,
                        image: page.seo.image ? `${rootUrl}${page.seo.image}` : seoService.defaultImage(rootUrl),
                    },
                    {
                        siteAccount: page.seo.twitter?.siteAccount || seoService.defaultTwitterSiteAccount(),
                        creatorAccount: page.seo.twitter?.creatorAccount || seoService.defaultTwitterCreatorAccount(),
                    },
                )}
            </Helmet>

            <SectionsRenderer sections={page.sections} locale={page.locale as string} />
        </div>
    );
}
