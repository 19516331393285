import { Button, Cta } from './components/cta';
import Highlighter from './components/highlighter';

export default function InterconnectedTools({
    title,
    paragraphs,
    imageSource,
    buttons,
}: {
    title: string;
    paragraphs: string[];
    imageSource: string;
    buttons: Button[];
}) {
    return (
        <div className="overflow-hidden bg-white">
            <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
                <div className="flex items-center">
                    <div className="flex-1 px-6 md:px-0 lg:pr-4 lg:pt-4">
                        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
                            <h2 className="text-3xl font-bold tracking-normal text-gray-900 sm:text-4xl">
                                <Highlighter colored={true}>{title}</Highlighter>
                            </h2>
                            {paragraphs &&
                                paragraphs.map((paragraph) => (
                                    <p key={paragraph} className="mt-6 text-gray-500 sm:text-xl">
                                        <Highlighter colored={false}>{paragraph}</Highlighter>
                                    </p>
                                ))}
                            <div className="flex mt-4">
                                {buttons?.map((btn, i) => (
                                    <div key={i}>
                                        {Cta({
                                            ...btn,
                                            className:
                                                i === 0
                                                    ? 'select-none cursor-pointer rounded-md bg-gradient-to-r from-[#DB1783] to-orange-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:brightness-110 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600'
                                                    : 'select-none cursor-pointer bg-gradient-to-r from-[#DB1783] to-orange-500 bg-clip-text text-sm font-semibold leading-6 text-gray-900 text-transparent hover:brightness-150',
                                        })}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 sm:px-6 lg:px-0">
                        <div className="mt-5 flex-none">
                            <div className="rounded-xl bg-gray-900/5 ring-1 ring-inset ring-gray-900/10 w-full p-4">
                                <img src="https://placehold.co/600x400" alt="Product screenshot of connected tools" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
