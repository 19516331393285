import React from 'react';
import { hydrate, render } from 'react-dom';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import './index.scss';
import { registerCallableComponent } from './service/registerCallableComponents';
import Faq from './sections/faq';
import HeroV3 from './sections/hero-v3';
import Header from './sections/header';
import ForCustomers from './sections/for-customers';
import CustomerUseCases from './sections/use-cases';
import ForTeams from './sections/for-teams';
import Arrr from './sections/arrr';
import UnlimitedPossibilities from './sections/unlimited-possibilities';
import Footer from './sections/footer';
import JoinBetaProgram from './sections/modals/join-beta-program';
import NewsletterSubscription from './sections/newsletterSubscription';
import { registerComponent } from './service/registerComponents';
import HeroColored from './sections/hero-colored';
import InterconnectedTools from './sections/interconnected-tools';
import { BackgroundTop } from './sections/backgroundTop';

// Polyfill Object.hasOwn for react-markdown with react-snap
// From : https://github.com/remarkjs/react-markdown/issues/772
if (!Object.hasOwn) {
    Object.hasOwn = (obj, prop) => Object.prototype.hasOwnProperty.call(obj, prop);
}

registerComponent('hero', HeroV3);
registerComponent('background-top', BackgroundTop);
registerComponent('header', Header);
registerComponent('hero-colored', HeroColored);
registerComponent('interconnected-tools', InterconnectedTools);
registerComponent('for-customers', ForCustomers);
registerComponent('customer-use-cases', CustomerUseCases);
registerComponent('for-faq', Faq);
registerComponent('for-teams', ForTeams);
registerComponent('arrr', Arrr);
registerComponent('unlimited-possibilities', UnlimitedPossibilities);
registerComponent('newsletter-subscription', NewsletterSubscription);
registerComponent('footer', Footer);

registerCallableComponent('joinBetaProgram', JoinBetaProgram);

// registerBlogComponents(Article, ArticlesList); // Default is already setted

const rootElement = document.getElementById('root') as HTMLElement;

if (rootElement.hasChildNodes()) {
    hydrate((<RouterProvider router={router} />) as any, rootElement);
} else {
    render((<RouterProvider router={router} />) as any, rootElement);
}
