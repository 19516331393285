import { AuthorModel } from '../service/database';
import Markdown from 'react-markdown';
import rehypeSlug from 'rehype-slug';
import rehypeShiftHeading from 'rehype-shift-heading';
// @ts-ignore
import rehypeFigure from 'rehype-figure';

export function Author({ url, author }: { url: string; author: AuthorModel }) {
    return (
        <div className="mx-auto max-w-5xl">
            <header className="flex">
                <h1 className="text-2xl lg:text-5xl my-8 lg:my-20 mx-4 lg:mx-8 flex-1 text-center font-semibold text-gray-900">
                    {author.firstname + ' ' + author.lastname}
                </h1>
            </header>
            <section className="flex">
                <article className="flex-1 bg-white shadow mx-auto max-w-prose mb-16 lg:rounded-lg lg:mr-8 lg:ml-0">
                    <div className="px-4 py-5 lg:p-6">
                        <section className="reader">
                            <Markdown
                                rehypePlugins={[
                                    rehypeSlug,
                                    [rehypeShiftHeading, { shift: 1 }],
                                    [rehypeFigure, { className: 'caption' }],
                                ]}
                            >
                                {author.bio}
                            </Markdown>
                        </section>
                        <footer className="flex mt-6">
                            <img src="/default-avatar.png" alt="user avatar" className="h-10 rounded rounded-full" />
                            <div className="flex flex-col ml-2">
                                {author.firstname} {author.lastname} <br />
                                {author?.twitterAccount && (
                                    <a
                                        href={'https://x.com/' + author.twitterAccount}
                                        className="text-sm hover:underline"
                                    >
                                        @{author.twitterAccount}
                                    </a>
                                )}
                            </div>
                        </footer>
                    </div>
                </article>
            </section>
        </div>
    );
}
