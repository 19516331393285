import {
    ArticleModel,
    AuthorModel,
    CategoryModel,
    DatabaseImplementation,
    PageModel,
    SettingModel,
    Status,
} from '../database';
import { mapToArticleModel, mapToAuthorModel, mapToCategoryModel, mapToPageModel, mapToSettingModel } from '../mappers';

/**
 * FileDb is a database implementation that reads static data on an uri
 * These data are stored in a json file fixed at the build time
 * These data come from dynamic db content
 */
class FileDb implements DatabaseImplementation {
    private readonly fileDbPath = '/generatedFileDb';

    constructor(private readonly fetch: any) {
        console.log('Database implementation - ', 'FileDb');
    }

    /* --- Setting (global website settings) --- */

    async getSetting(status: Status): Promise<SettingModel> {
        const settings: any = await this.fetch(`${this.fileDbPath}/settings.json`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then((res: any) => res.json());
        console.log(settings);
        return this.mapToSettingModel(settings);
    }

    /* Articles (blog) */

    async getArticles(): Promise<ArticleModel[]> {
        const allArticles: any = await this.fetch(`${this.fileDbPath}/articles.json`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then((res: any) => res.json());
        console.log(allArticles);
        return allArticles.map((article: any) => this.mapToArticleModel(article));
    }

    async getArticleBySlug(path: string): Promise<ArticleModel> {
        const { article } = (await this.fetch(`${this.fileDbPath}/articles-${path.replace('/', '--')}.json`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then((res: any) => res.json())) as any;
        console.log(article);
        return this.mapToArticleModel(article);
    }

    /* Authors (blog) */

    async getAuthors(): Promise<AuthorModel[]> {
        const allAuthors: any = await this.fetch(`${this.fileDbPath}/authors.json`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then((res: any) => res.json());
        console.log(allAuthors);
        return allAuthors.map((author: any) => this.mapToAuthorModel(author));
    }

    async getAuthorBySlug(path: string): Promise<AuthorModel> {
        const { author } = (await this.fetch(`${this.fileDbPath}/authors-${path.replace('/', '--')}.json`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then((res: any) => res.json())) as any;
        console.log(author);
        return this.mapToAuthorModel(author);
    }

    /* Categories (blog) */

    async getCategories(): Promise<CategoryModel[]> {
        const allCategories: any = await this.fetch(`${this.fileDbPath}/categories.json`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then((res: any) => res.json());
        console.log(allCategories);
        return allCategories.map((category: any) => this.mapToCategoryModel(category));
    }

    async getCategoryBySlug(path: string): Promise<CategoryModel> {
        const { category } = (await this.fetch(`${this.fileDbPath}/categories-${path.replace('/', '--')}.json`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then((res: any) => res.json())) as any;
        console.log(category);
        return this.mapToCategoryModel(category);
    }

    /* Pages (landing) */

    async getPages(): Promise<PageModel[]> {
        const allPages: any = await this.fetch(`${this.fileDbPath}/pages.json`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then((res: any) => res.json());
        console.log(allPages);
        return allPages.map((page: any) => this.mapToPageModel(page));
    }

    async getPageBySlug(path: string): Promise<PageModel> {
        const { page } = (await this.fetch(`${this.fileDbPath}/pages-${path.replace('/', '--')}.json`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then((res: any) => res.json())) as any;
        console.log(page);
        return this.mapToPageModel(page);
    }

    /* --- Mapping functions --- */

    private mapToPageModel(page: any) {
        return mapToPageModel(page);
    }

    private mapToArticleModel(article: any) {
        return mapToArticleModel(article);
    }

    private mapToAuthorModel(author: any) {
        return mapToAuthorModel(author);
    }

    private mapToCategoryModel(category: any) {
        return mapToCategoryModel(category);
    }

    private mapToSettingModel(setting: any) {
        return mapToSettingModel(setting);
    }
}

export default FileDb;
