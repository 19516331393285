/**
 * Callable components are invisible components displayed on click of a button.
 * Modal for example.
 * They handle an internal state and are provided with a function to open them.
 */
type CallableComponent = (props: any) => [() => void, any];

interface CallableComponentWithInstance {
    component: (props: any) => [() => void, any];
    instance: () => void;
}

const registeredCallableComponentsWithInstances: { [key: string]: CallableComponentWithInstance } = {};

export function registerCallableComponent(name: string, callableComponent: CallableComponent) {
    console.log('registerCallableComponent', name);
    registeredCallableComponentsWithInstances[name] = { component: callableComponent, instance: () => {} };
    return registeredCallableComponentsWithInstances[name];
}

function getCallableComponentWithInstance(name?: string): CallableComponentWithInstance {
    console.log('getCallableComponentWithInstance', name);
    const defaultInstance: CallableComponentWithInstance = {
        component: () => [() => {}, <></>],
        instance: () => {},
    };
    if (!name) {
        return defaultInstance;
    }
    const callableComponentWithInstance = registeredCallableComponentsWithInstances[name];
    if (!callableComponentWithInstance) {
        return defaultInstance;
    }
    return registeredCallableComponentsWithInstances[name];
}

export function getCallableComponent(name?: string): CallableComponent {
    console.log('getCallableComponent', name, registeredCallableComponentsWithInstances);
    return getCallableComponentWithInstance(name).component;
}

export function getCallableComponentInstance(name?: string): () => void {
    console.log('getCallableComponentInstance', name);
    return getCallableComponentWithInstance(name).instance;
}

export function addCallableFunctionInstance(name?: string, callableFunctionInstance?: () => void) {
    console.log('addCallableFunctionInstance', name);
    getCallableComponentWithInstance(name).instance = callableFunctionInstance || (() => {});
}
