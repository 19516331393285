import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Button, Cta } from './components/cta';
import { useLocation } from 'react-router-dom';

interface HeaderInputs {
    logo: {
        img: string;
        href: string;
    };
    logoSmall: string;
    companyName: string;
    navigation: { name: string; href: string }[];
    cta: Button;
}

export default function Header({ companyName, logo, logoSmall, navigation, cta }: HeaderInputs) {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const location = useLocation();

    return (
        <>
            <div className="w-100 fixed top-0 left-0 right-0 z-50 bg-white">
                <header className="absolute inset-x-0 top-0 z-50 backdrop-blur-lg">
                    <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                        <div className="flex lg:flex-1">
                            <a href={logo.href} className="-m-1.5 p-1.5">
                                <span className="sr-only">{companyName}</span>
                                <img className="h-8 w-auto" src={logo.img} alt="logo" />
                            </a>
                        </div>
                        <div className="flex lg:hidden">
                            <button
                                type="button"
                                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                                onClick={() => setMobileMenuOpen(true)}
                            >
                                <span className="sr-only">Open main menu</span>
                                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="hidden lg:flex lg:gap-x-12">
                            {navigation.map((item) => (
                                <Cta
                                    key={item.name}
                                    className={`text-sm font-semibold leading-6 text-gray-900 hover:bg-gradient-to-r hover:from-[#DB1783] hover:to-orange-500 hover:bg-[length:100%_3px] hover:bg-bottom hover:bg-no-repeat ${
                                        location.pathname === item.href
                                            ? 'bg-gradient-to-r from-[#DB1783] to-orange-500 bg-[length:100%_3px] bg-bottom bg-no-repeat'
                                            : ''
                                    }`}
                                    label={item.name}
                                    type="link"
                                    action={item.href}
                                />
                            ))}
                        </div>
                        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                            <Cta
                                {...cta}
                                className={
                                    'text-sm font-semibold leading-6 text-gray-900 hover:bg-gradient-to-r hover:from-[#DB1783] hover:to-orange-500 hover:bg-[length:100%_3px] hover:bg-bottom hover:bg-no-repeat'
                                }
                            />
                        </div>
                    </nav>
                    <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                        <div className="fixed inset-0 z-50" />
                        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                            <div className="flex items-center justify-between">
                                <a href="src/component/page#" className="-m-1.5 p-1.5">
                                    <span className="sr-only">{companyName}</span>
                                    <img className="h-8 w-auto" src={logoSmall} alt="small logo" />
                                </a>
                                <button
                                    type="button"
                                    className="-m-2.5 rounded-md p-2.5 text-gray-700"
                                    onClick={() => setMobileMenuOpen(false)}
                                >
                                    <span className="sr-only">Close menu</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                            <div className="mt-6 flow-root">
                                <div className="-my-6 divide-y divide-gray-500/10">
                                    <div className="space-y-2 py-6">
                                        {navigation.map((item) => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 ${
                                                    location.pathname === item.href
                                                        ? 'bg-gradient-to-r from-[#DB1783] to-orange-500 bg-[length:100%_3px] bg-bottom bg-no-repeat'
                                                        : ''
                                                }`}
                                            >
                                                {item.name}
                                            </a>
                                        ))}
                                    </div>
                                    <div className="py-6">
                                        <a
                                            href="src/component/page#"
                                            className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            Log in
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Dialog>
                </header>
            </div>
            <div className="mt-20"></div>
        </>
    );
}
