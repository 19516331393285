import React from 'react';
import { getCallableComponentInstance } from '../../service/registerCallableComponents';
import Highlighter from './highlighter';
import { Link } from 'react-router-dom';

export interface Button {
    label: string;
    action: string;
    type: string;
    className: string;
}

export function Cta(button: Button) {
    /*
        const style: { [k: string]: any } = {
            primary:
                'select-none cursor-pointer rounded-md bg-gradient-to-r from-[#DB1783] to-orange-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:brightness-110 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600',
            secondary:
                'select-none cursor-pointer bg-gradient-to-r from-[#DB1783] to-orange-500 bg-clip-text text-sm font-semibold leading-6 text-gray-900 text-transparent hover:brightness-150',
        };
    */
    return button.type === 'function' ? (
        <button onClick={getCallableComponentInstance(button.action)} className={button.className}>
            {button.label}
        </button>
    ) : (
        <Link to={button.action} className={button.className}>
            <Highlighter colored={false}>{button.label}</Highlighter>
        </Link>
    );
}
