import * as solidIcon from '@heroicons/react/20/solid';
import Highlighter from './components/highlighter';

/*
1. Unlimited notification content possibilities
2. Notification Center customization
3. Live preview notification editor
4. Inform in real-time, when event happens (web and mobile screenshot)
5. Give your users power on their notification management
*/

/*
const features = [
  {
    name: "Unlimited notification content possibilities",
    description: "Lorem ipsitiis ratione.",
    icon: CloudArrowUpIcon,
  },
  {
    name: "Notification Center customization",
    description: "Anim ",
    icon: LockClosedIcon,
  },
  {
    name: "Live preview notification editor",
    description: "Ac t",
    icon: ServerIcon,
  },
  {
    name: "Inform in real-time, when event happens",
    description: "Ac",
    icon: ServerIcon,
  },
  {
    name: "Give your users power on their notification management",
    description: "Ac",
    icon: ServerIcon,
  },
];

const codeString =
  '<script src="in-app-notification-center.js" />\n' +
  "<script>\n" +
  "  center.init({})\n" +
  "</script>";
*/
export default function ForCustomers({
    title,
    paragraphs,
    useCases,
    videoSource,
}: {
    title: string;
    paragraphs: string[];
    useCases: { icon: string; name: string; description: string }[];
    videoSource: string;
}) {
    return (
        <div className="overflow-hidden bg-white">
            <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
                <div className="flex items-center">
                    <div className="flex-1 px-6 md:px-0 lg:pr-4 lg:pt-4">
                        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
                            <h2 className="text-3xl font-bold tracking-normal text-gray-900 sm:text-4xl">
                                <Highlighter colored={true}>{title}</Highlighter>
                            </h2>
                            {paragraphs &&
                                paragraphs.map((paragraph) => (
                                    <p key={paragraph} className="mt-6 text-gray-500 sm:text-xl">
                                        <Highlighter colored={false}>{paragraph}</Highlighter>
                                    </p>
                                ))}
                        </div>
                    </div>
                    <div className="flex-1 sm:px-6 lg:px-0">
                        <div className="mt-5 flex-none">
                            <div className="rounded-xl bg-gray-900/5 ring-1 ring-inset ring-gray-900/10 w-full p-4">
                                {videoSource && (
                                    <video className="h-full w-full rounded" controls>
                                        <source src={videoSource} />
                                        Your browser does not support the video tag.
                                    </video>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {useCases && useCases.length > 0 && (
                <div className="mx-auto mt-32 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                    <dl className="mt-32 grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
                        {useCases.map((useCase) => {
                            // @ts-ignore
                            const Icon = solidIcon[useCase.icon];
                            return (
                                <div key={useCase.name} className="relative pl-16">
                                    <dt className="text-base font-semibold leading-7 text-gray-900">
                                        <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-gradient-to-r from-[#DB1783] to-orange-500">
                                            <Icon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </div>
                                        {useCase.name}
                                    </dt>
                                    <dd className="mt-2 text-base leading-7 text-gray-600">{useCase.description}</dd>
                                </div>
                            );
                        })}
                    </dl>
                </div>
            )}
        </div>
    );
}
