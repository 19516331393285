import { getComponent } from '../service/registerComponents';
import { addCallableFunctionInstance, getCallableComponent } from '../service/registerCallableComponents';
import React from 'react';
import { SectionModel } from '../service/database';

export function SectionsRenderer({ sections, locale }: { sections: SectionModel[]; locale: string }) {
    return sections.map((section, index) => {
        console.log('Load section: ', section.type, section.properties);
        if (getComponent(section.type)) {
            const Section = getComponent(section.type);
            return <Section {...section.properties} locale={locale} key={index} />;
        }
        if (getCallableComponent(section.type)) {
            const callableComponent = getCallableComponent(section.type)({ key: index, ...section.properties });
            const callableFunction = callableComponent[0];
            addCallableFunctionInstance(section.type, callableFunction);
            return callableComponent[1];
        }
        return <div className="missing-component"></div>;
    });
}
