import { ArticleModel } from '../service/database';
import Markdown from 'react-markdown';
import rehypeSlug from 'rehype-slug';
import rehypeShiftHeading from 'rehype-shift-heading';
// @ts-ignore
import rehypeFigure from 'rehype-figure';
import { ShareButtons } from '../sections/components/shareButtons';

export function Article({ url, article }: { url: string; article: ArticleModel }) {
    const articleSummaryTitles = [...(article.content || '')?.matchAll(/(^#+\s)(.*)/gm)];

    return (
        <div className="mx-auto max-w-5xl">
            <header className="flex">
                <h1 className="text-2xl lg:text-5xl my-8 lg:my-20 mx-4 lg:mx-8 flex-1 text-center font-semibold text-gray-900">
                    {article.title}
                </h1>
            </header>
            <section className="flex">
                <aside className="w-80 mx-8 hidden lg:flex lg:flex-col">
                    <section className="bg-white shadow lg:rounded-lg">
                        <div className="px-4 py-5 lg:p-6">
                            <h3 className="text-base font-semibold leading-4 text-gray-900">Content</h3>
                            <div className="mt-4 lg:flex lg:items-start lg:justify-between">
                                <div className="text-sm text-gray-700">
                                    <ul className="list-decimal ml-4">
                                        {articleSummaryTitles.map((title, index) => (
                                            <li key={index}>
                                                <a
                                                    href={`#${title[2]
                                                        .replace(/ /g, '-')
                                                        .replace(/[^\w-]+/g, '')
                                                        .toLowerCase()}`}
                                                >
                                                    {title[2]}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="bg-white shadow lg:rounded-lg w-80 p-2 mt-4">
                        {(article.image || article.seo?.image) && (
                            <img
                                className="rounded rounded-xl"
                                src={article.image || article.seo.image}
                                alt={article.title + ' image.'}
                            />
                        )}
                    </section>
                    <section className="w-80 p-2 mt-4 flex items-center justify-center gap-2">
                        <div>Share</div>
                        <ShareButtons
                            url={url}
                            title={article.title}
                            twitterAccount={article.seo.twitter?.siteAccount}
                        />
                    </section>
                </aside>
                <article className="flex-1 bg-white shadow mx-auto max-w-prose mb-16 lg:rounded-lg lg:mr-8 lg:ml-0">
                    <div className="px-4 py-5 lg:p-6">
                        <section className="py-2 flex items-center justify-center gap-2 lg:hidden">
                            <ShareButtons
                                url={url}
                                title={article.title}
                                twitterAccount={article.seo.twitter?.siteAccount}
                            />
                        </section>
                        <section className="reader">
                            <Markdown
                                rehypePlugins={[
                                    rehypeSlug,
                                    [rehypeShiftHeading, { shift: 1 }],
                                    [rehypeFigure, { className: 'caption' }],
                                ]}
                            >
                                {article.content}
                            </Markdown>
                        </section>
                        <footer className="flex mt-6">
                            <img src="/default-avatar.png" alt="user avatar" className="h-10 rounded rounded-full" />
                            <div className="flex flex-col ml-2">
                                {article.author.firstname} {article.author.lastname} <br />
                                {article.author?.twitterAccount && (
                                    <a
                                        href={'https://x.com/' + article.author.twitterAccount}
                                        className="text-sm hover:underline"
                                    >
                                        @{article.author.twitterAccount}
                                    </a>
                                )}
                            </div>
                        </footer>
                    </div>
                </article>
            </section>
        </div>
    );
}
