import * as solidIcon from '@heroicons/react/20/solid';
import Highlighter from './components/highlighter';

export default function Arrr({
    title,
    subtitle,
    features,
}: {
    title: string;
    subtitle: string;
    features: { href: string; icon: string; name: string; commingSoon: boolean; paragraphs: string[] }[];
}) {
    return (
        <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:text-center">
                    <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        <Highlighter colored>{title}</Highlighter>
                    </h2>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                        <Highlighter colored={false}>{subtitle}</Highlighter>
                    </p>
                </div>
                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                        {features.map((feature) => {
                            // @ts-ignore
                            const Icon = solidIcon[feature.icon];

                            return (
                                <div key={feature.name} className="flex flex-col">
                                    <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                                        <Icon className="h-5 w-5 flex-none text-orange-600" aria-hidden="true" />
                                        {feature.name}
                                        {feature.commingSoon && (
                                            <span className="rounded-full bg-orange-600/10 px-3 py-0.5 text-sm font-semibold leading-6 text-orange-600 ring-1 ring-inset ring-orange-600/10">
                                                coming soon
                                            </span>
                                        )}
                                    </dt>
                                    <dd className="mt-4 flex flex-col gap-3 text-base leading-7 text-gray-600">
                                        {feature.paragraphs.map((paragraph) => (
                                            <p key={paragraph}>
                                                <Highlighter colored={false}>{paragraph}</Highlighter>
                                            </p>
                                        ))}
                                    </dd>
                                </div>
                            );
                        })}
                    </dl>
                </div>
            </div>
        </div>
    );
}
