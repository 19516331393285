import Highlighter from './components/highlighter';

export default function ForTeams({
    imageSource,
    title,
    subtitle,
    paragraphs,
}: {
    imageSource: string;
    title: string;
    subtitle: string;
    paragraphs: string[];
}) {
    return (
        <div className="overflow-hidden bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="lg:ml-auto lg:pl-4 lg:pt-4">
                        <div className="lg:max-w-lg">
                            <h2 className="mt-2 text-3xl font-bold tracking-tight sm:text-4xl">
                                <Highlighter colored>{title}</Highlighter>
                            </h2>
                            <div className="mt-4 text-2xl tracking-tight">
                                <Highlighter colored={false}>{subtitle}</Highlighter>
                            </div>
                            <div className="flex flex-col gap-2 mt-5">
                                {paragraphs.map((paragraph) => (
                                    <p className="text-lg leading-8 text-gray-600">{paragraph}</p>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="flex items-start justify-end lg:order-first">
                        <img
                            src={imageSource}
                            alt="Product screenshot"
                            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
                            width={1216}
                            height={721}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
