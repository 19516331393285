import React from 'react';

export interface BaseSeoMetadata {
    pageUrl?: string;
    description?: string;
    title?: string;
    image?: string;
}

export interface ArticleMetadata {
    publishedAt?: string;
    updatedAt?: string;
    author?: string;
}

export interface ProfileMetadata {
    firstname?: string;
    lastname?: string;
    username?: string;
}

export interface TwitterMetadata {
    siteAccount?: string;
    creatorAccount?: string;
}

class SeoService {
    noFollowMetaTag() {
        return [<meta name="robots" content="nofollow" />];
    }

    basicMetaTags(metaData: BaseSeoMetadata) {
        return [
            ...this.noFollowMetaTag(),
            metaData.pageUrl && <link rel="canonical" href={metaData.pageUrl} />,
            metaData.pageUrl && <meta property="og:url" content={metaData.pageUrl} />,
            metaData.description && <meta name="description" content={metaData.description} />,
            metaData.title && <title>{metaData.title}</title>,
            metaData.image && <meta property="og:image" content={metaData.image} />,
        ].filter((m) => !!m);
    }

    opengraphProfileMetaTags(profile: ProfileMetadata) {
        return [
            <meta property="og:type" content="profile" />,
            profile.firstname && <meta name="profile:first_name" content={profile.firstname} />,
            profile.lastname && <meta name="profile:last_name" content={profile.lastname} />,
            profile.username && <meta name="profile:username" content={profile.username} />,
        ].filter((m) => !!m);
    }

    opengraphArticleMetaTags(article: ArticleMetadata) {
        return [
            <meta property="og:type" content="article" />,
            article.publishedAt && <meta name="article:published_time" content={article.publishedAt} />,
            article.updatedAt && <meta name="article:modified_time" content={article.updatedAt} />,
            article.author && <meta name="article:author" content={article.author} />,
        ].filter((m) => !!m);
    }

    opengraphWebsiteMetaTags() {
        return [<meta property="og:type" content="website" />];
    }

    opengraphLocales(locale: string, alternativeLocales: string[]) {
        return [
            locale && <meta property="og:locale" content={locale.replace('-', '_')} />,
            ...alternativeLocales.map((alternativeLocale) => (
                <meta property="og:locale:alternate" content={alternativeLocale.replace('-', '_')} />
            )),
        ].filter((m) => !!m);
    }

    twitterMetaTags(metaData: BaseSeoMetadata, twitter: TwitterMetadata) {
        return [
            <meta name="twitter:card" content="summary_large_image" />,
            twitter.siteAccount && <meta name="twitter:site" content={twitter.siteAccount} />,
            twitter.creatorAccount && <meta name="twitter:creator" content={twitter.creatorAccount} />,
            metaData.title && <meta name="twitter:title" content={metaData.title} />,
            metaData.description && <meta name="twitter:description" content={metaData.description} />,
            metaData.image && <meta name="twitter:image" content={metaData.image} />,
        ];
    }

    defaultTwitterSiteAccount() {
        return process.env.REACT_APP_TWITTER_SITE_ACCOUNT;
    }

    defaultTwitterCreatorAccount() {
        return process.env.REACT_APP_TWITTER_CREATOR_ACCOUNT;
    }

    defaultImage(rootUrl: string) {
        return rootUrl + process.env.REACT_APP_DEFAULT_IMAGE;
    }
}

const seoService = new SeoService();
export { seoService };
