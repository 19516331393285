import React from 'react';
import { createBrowserRouter, RouteObject } from 'react-router-dom';
import databaseService from './service/databaseService';
import { PageRenderer } from './pathRenderer/pageRenderer';
import { ArticleRenderer } from './pathRenderer/articleRenderer';
import {
    buildArticlesListUrl,
    buildArticleUrlFromPath,
    buildAuthorUrlFromPath,
    buildCategoryUrlFromPath,
    buildPageUrl,
} from './urlBuilder';
import { ArticleListRenderer } from './pathRenderer/articleListRenderer';
import { onlyUnique } from './onlyUnique';
import { AuthorRenderer } from './pathRenderer/authorRenderer';
import { CategoryRenderer } from './pathRenderer/categoryRenderer';

// TODO Rework router behaviour to avoid the download of all pages and articles at the beginning of page load
// TODO Use instead a generic route with param. And load the page or article only when the route is called from isolated json page--one-page-example.json
// TODO React Snap still need to download all pages and articles to generate the static snapshot. IndexPageForReactSnapshot or equivalent need to to reference everything.

// @ts-ignore
const pages = await databaseService.getPages();
// @ts-ignore
const articles = await databaseService.getArticles();
// @ts-ignore
const authors = await databaseService.getAuthors();
// @ts-ignore
const categories = await databaseService.getCategories();
// @ts-ignore
const setting = await databaseService.getSetting();

const pagesRoutes = pages.map((page) => {
    return {
        path: buildPageUrl(page.locale as string, page.path as string, setting),
        element: <PageRenderer allPages={pages} page={page} />,
    };
});

let categoriesRoutes: RouteObject[] = [];
let authorsRoutes: RouteObject[] = [];
let articlesRoutes: RouteObject[] = [];
let articlesListRoutes: RouteObject[] = [];

if (setting.blogEnable) {
    articlesRoutes = articles.map((article) => {
        return {
            path: buildArticleUrlFromPath(article.locale as string, article.path as string, setting),
            element: <ArticleRenderer allArticles={articles} article={article} setting={setting} />,
        };
    });

    articlesListRoutes = articles
        .map((a) => a.locale)
        .filter(onlyUnique)
        .map((locale) => {
            return {
                path: buildArticlesListUrl(locale as string, setting),
                element: <ArticleListRenderer articles={articles} locale={locale as string} setting={setting} />,
            };
        });

    authorsRoutes = authors.map((author) => {
        return {
            path: buildAuthorUrlFromPath(author.locale as string, author.path as string, setting),
            element: <AuthorRenderer author={author} setting={setting} />,
        };
    });

    categoriesRoutes = categories.map((category) => {
        return {
            path: buildCategoryUrlFromPath(category.locale as string, category.path as string, setting),
            element: <CategoryRenderer category={category} setting={setting} />,
        };
    });
}

const allRoutes = ([] as RouteObject[]).concat(
    pagesRoutes,
    articlesRoutes,
    articlesListRoutes,
    authorsRoutes,
    categoriesRoutes,
);

const snapshotRoute = [{ path: '/snapshot', element: <IndexPageForReactSnapshot allRoutes={allRoutes} /> }];

// Global router
export default createBrowserRouter(([] as any).concat(allRoutes, snapshotRoute));

// Index of all page to facilitate the react-snap static snapshot
function IndexPageForReactSnapshot({ allRoutes }: { allRoutes: RouteObject[] }): any {
    return (
        <div>
            {allRoutes.map((route) => (
                <a href={route.path}>{route.path}</a>
            ))}
        </div>
    );
}
