import { CategoryModel } from '../service/database';
// @ts-ignore

export function Category({ url, category }: { url: string; category: CategoryModel }) {
    return (
        <div className="mx-auto max-w-5xl">
            <header className="flex">
                <h1 className="text-2xl lg:text-5xl my-8 lg:my-20 mx-4 lg:mx-8 flex-1 text-center font-semibold text-gray-900">
                    {category.name}
                </h1>
            </header>
            <section className="flex">
                <article className="flex-1 bg-white shadow mx-auto max-w-prose mb-16 lg:rounded-lg lg:mr-8 lg:ml-0">
                    <div className="px-4 py-5 lg:p-6">
                        <section>{category.description}</section>
                    </div>
                </article>
            </section>
        </div>
    );
}
